<template lang="pug">
  div.home
    MainHeadItem(iconName="admin" iconW="30" iconH="20" title="管理メニュー" @click.native="darkMenu")
      IconAdmin
    ul.admin_menu
      li
        router-link(:to="{name: 'admin_register'}")
          IconBase(width="40" height="28" icon-name="register")
            IconRegister
          span 社員登録
      li
        router-link(:to="{name: 'admin_list'}")
          IconBase(width="30" height="30" icon-name="admin-list")
            IconAdminList
          span 社員一覧
      li
        router-link(:to="{name: 'admin_master'}")
          IconBase(width="30" height="30" icon-name="master")
            IconMaster
          span マスタ管理

    .push_notification_wrap(v-if="darkMenuCount > 8")
      h2 PUSH通知

      form.push_notification_form(@submit.prevent="onConfirm")
        .input_wrap
          label(for="title") タイトル
          input#title(type="text" v-model.trim="formData.title" placeholder="タイトル" required)
        .input_wrap
          label(for="body") 本文
          input#body(type="text" v-model.trim="formData.body" placeholder="通知文" required)

        button.confirm_btn(type="submit") PUSH通知

        p.attention ※<b>{{ this.haveFCMTokenUsers.length}}名</b>のユーザにPUSH通知が送信されます。

</template>

<script>
import { sendMessage, sendMessageForWeb } from '@/apis/firebaseMessaging'
import MainHeadItem from '@/components/MainHeadItem.vue'
import IconBase from '@/components/IconBase.vue'
import IconAdmin from '@/components/icons/IconAdmin.vue'
import IconRegister from '@/components/icons/IconRegister.vue'
import IconAdminList from '@/components/icons/IconAdminList.vue'
import IconMaster from '@/components/icons/IconMaster.vue'
import { mapState } from 'vuex'
export default {
  name: 'AdminHomeView',
  metaInfo: {
    title: '【管理】ホーム画面',
  },
  components: {
    MainHeadItem,
    IconBase,
    IconAdmin,
    IconRegister,
    IconAdminList,
    IconMaster,
  },
  data() {
    return {
      darkMenuCount: 0,
    }
  },
  props: {
    formData: {
      typeof: Object,
      required: false,
      default: () => {
        return {
          title: '',
          body: '',
        }
      },
    },
  },
  computed: {
    ...mapState('usersModule', ['users']),
    haveFCMTokenUsers() {
      const haveFCMTokenUsers = []
      Object.keys(this.users).forEach((id) => {
        const account = this.users[id]
        const fcmTokens = account.webFcmToken
        if (fcmTokens && fcmTokens.length) {
          haveFCMTokenUsers.push(account)
          console.log(account.name, account.webFcmToken) // eslint-disable-line no-console
        }
      })
      return haveFCMTokenUsers
    },
  },
  methods: {
    darkMenu() {
      this.darkMenuCount++
      if (this.darkMenuCount >= 15) {
        this.darkMenuCount = 0
      }
    },
    onConfirm() {
      if (this.formData.title.length < 0 || this.formData.body.length < 0) {
        alert('タイトルと本文は必須項目です。')
      } else {
        if (confirm('Push通知を送信しますか？')) {
          // アプリ用PUSH通知
          sendMessage(this.formData.title, this.formData.body, 1, 'ems')
          // Web用Push通知
          sendMessageForWeb(this.formData.title, this.formData.body, this.users)
          this.formData.title = ''
          this.formData.body = ''
          alert(
            'PUSH通知を送信しました。\n※通知が届くまでに時間がかかることがあります。',
          )
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  .admin_menu {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px 25px;
    @include tab {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      width: 100%;
      @include tab {
        width: calc(100% / 3 - 30px);
      }

      border-radius: 20px;
      border: 2px solid #88cabe;
      margin: 20px auto;
      @include tab {
        margin-bottom: 20px;
      }
      &:first-child {
        margin-top: 0;
        @include tab {
          margin-top: 20px;
        }
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px 15px;
        color: #88cabe;
        text-decoration: none;
        text-align: center;
        span {
          margin: 0 0 0 10px;
          @include tab {
            display: block;
            width: 100%;
            margin: 8px 0 0;
          }
          font-size: 18px;
        }
      }
    }
  }
  .push_notification_wrap {
    width: calc(100% - 80px);
    max-width: 800px;
    margin: 0 auto 20px;
    border: 2px solid #efa474;
    border-radius: 20px;
    padding: 20px;
    h2 {
      margin: 0 auto 10px;
      font-weight: bold;
      color: #777;
    }
    .push_notification_form {
      .input_wrap {
        margin: 20px auto 10px;
        label {
          display: block;
          color: #777;
          margin: 0 auto 5px;
          text-align: left;
        }
      }
      input {
        width: 100%;
        line-height: 1.2;
        font-size: 13px;
        letter-spacing: 2px;
        padding: 10px 15px;
        display: block;
        width: calc(100% - 30px);
        border-radius: 10px;
        border: 1px solid #d1d1d1;
        background: #fff;
        resize: none;
      }
    }
    .confirm_btn {
      cursor: pointer;
      display: block;
      margin: 20px auto 0;

      text-align: center;
      border-radius: 10px;
      padding: 10px 15px;
      background-color: #88cabe;
      background-image: linear-gradient(315deg, #e4a78b 0%, #f3c970 74%);
      color: #fff;
      font-size: 15px;
      cursor: pointer;
    }
    .attention {
      margin: 15px 0 auto;
      color: #ef7474;
      b {
        font-weight: bold;
        font-size: 1.2em;
      }
    }
  }
}
</style>
